import {
  DefaultButton,
  Dropdown,
  Stack,
  TextField,
  Toggle,
} from "@fluentui/react";
import { useAtom } from "jotai";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import FormSection from "../../../Common/FormSection/FormSection";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import Loader from "../../../Common/Loader/Loader";
import SelectManyFieldAsync from "../../../Common/SelectField/SelectManyFieldAsync/SelectManyFieldAsync";
import TextResult from "../../../Common/TextResult/TextResult";
import Tooltip from "../../../Common/Tooltip/Tooltip";
import { userSelectedBranchAtom } from "../../../atoms/branchAtom";
import { API_URL } from "../../../config/config";
import { ETAPES_CANDIDATURE_CONFIG_FILTER } from "../../../hooks/candidature/useCandidatureWorkflow";
import { getCertificationsRequest } from "../../../services/certificationServices";
import { getDomainesRequest } from "../../../services/domaineServices";
import { commonButtonStyles, commonInputStyles } from "../../../styles/index";
import tools from "../../../utils/tools";
import useLabelSettings from "../../LabelSettingsDetail/useLabelSettings";
import useCandidatureList from "../useCandidatureList";
import useInfoProfilUser from "../../../hooks/useInfoProfilUser";
import SelectFieldAsync from "../../../Common/SelectField/SelectFieldAsync/SelectFieldAsync";

function CandidatureListFilters() {
  const { labels } = useLabelSettings();
  const { isAnyKindOfAdmin } = useInfoProfilUser();
  const [userSelectedBranch] = useAtom(userSelectedBranchAtom);
  const [showFilters, setShowFilters] = useState(false);
  const location = useLocation();

  const { candidatures, isLoadingCandidatures, actions, reactHookProps } =
    useCandidatureList();
  const statutsList = ETAPES_CANDIDATURE_CONFIG_FILTER;

  const {
    data: certifications,
    isLoading: isLoadingCertifications,
    isRefetching: isRefetchingCertifications,
  } = useQuery(
    ["certifications", userSelectedBranch],
    () => {
      return getCertificationsRequest(
        {},
        { sort: "libelle", page: 1, paginate: false, searchAt: "" },
        userSelectedBranch.id
      );
    },
    {
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: domaines } = useQuery(
    ["domaines", userSelectedBranch],
    getDomainesRequest,
    {
      enabled: userSelectedBranch.gestion_par_domaine,
      retry: 0,
      refetchOnWindowFocus: false,
    }
  );

  const domainesFromApi =
    domaines?.data.map((domaine: any) => ({
      id: domaine.uuid,
      key: domaine.uuid,
      text: `${domaine.nom}`,
    })) || [];

  const certificationsFromApi =
    certifications?.data.map((certification: any) => ({
      id: certification.uuid,
      key: certification.uuid,
      text: `${certification.libelle} - ${certification.type_libelle}`,
    })) || [];
  const certificationList = [
    { id: undefined, text: "Toutes", key: "all" },
    ...certificationsFromApi,
  ];

  const totalCandidatures = candidatures?.meta.total || 0;
  const { handleSubmit, control, setValue, watch } = reactHookProps;

  const onReset = () => {
    actions.resetFilters();
    handleSubmit(actions.changeFilters)();
  };

  const toggleFilters = () => {
    setShowFilters((s) => !s);
  };

  useEffect(() => {
    const parsed: any = queryString.parse(location.search);
    if (parsed["statuts[]"]) {
      setValue(
        "statuts",
        typeof parsed["statuts[]"] == "string"
          ? [tools.findIn(statutsList, parsed["statuts[]"], "id")]
          : parsed["statuts[]"].map((s: any) =>
              tools.findIn(statutsList, s, "id")
            )
      );
      handleSubmit(actions.changeFilters)();
    }
  }, []);

  const onWrapDefaultLabelRenderer = (
    props: any,
    defaultRender: any
  ): JSX.Element => {
    return (
      <Stack horizontal verticalAlign="center">
        <span>{defaultRender(props)}</span>
        <Tooltip
          id={"nom-tooltip"}
          content={"Vous pouvez rechercher par nom d'usage ou nom de naissance"}
        />
      </Stack>
    );
  };

  return isLoadingCertifications || isRefetchingCertifications ? (
    <Loader />
  ) : (
    <FormSection sectionTitle="Filtres de recherche">
      <form onSubmit={handleSubmit(actions.changeFilters)}>
        <Stack
          style={{ marginBottom: 24 }}
          horizontalAlign="start"
          horizontal
          wrap
          className="ms-Grid"
        >
          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="nom"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Nom :"
                  styles={commonInputStyles.textField}
                  onRenderLabel={onWrapDefaultLabelRenderer}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              name="prenom"
              defaultValue=""
              control={control}
              render={({ field }) => (
                <TextField
                  label="Prénom :"
                  styles={commonInputStyles.textField}
                  type="text"
                  {...field}
                />
              )}
            />
          </Stack.Item>

          <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value } }) => (
                <Dropdown
                  label="Statuts de la candidature :"
                  selectedKeys={value ? value.map((v: any) => v.key) : []}
                  placeholder="Sélectionnez un ou plusieurs statuts"
                  options={statutsList}
                  onChange={(e: any, options: any) =>
                    actions.changeStatuts(value, options)
                  }
                  styles={commonInputStyles.dropdownStyle}
                  multiSelect
                />
              )}
              control={control}
              name="statuts"
            />
          </Stack.Item>

          {userSelectedBranch.gestion_par_domaine && (
            <Stack.Item
              className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
              style={{ paddingRight: 20, marginTop: 10 }}
            >
              <Controller
                render={({ field: { name, value, onBlur, ref } }) => (
                  <Dropdown
                    placeholder="Sélectionnez un domaine"
                    label="Domaines :"
                    selectedKeys={
                      value ? value.map((domaine: any) => domaine.key) : null
                    }
                    options={domainesFromApi}
                    styles={commonInputStyles.dropdownStyle}
                    calloutProps={{ doNotLayer: true }}
                    onChange={(e: any, options: any) => {
                      let actualValue = watch(name);

                      actualValue.find((o: any) => o.key == options.key)
                        ? (actualValue = actualValue.filter(
                            (o: any) => o.key != options.key
                          ))
                        : (actualValue = [...actualValue, options]);

                      setValue(name, actualValue, { shouldValidate: true });
                    }}
                    multiSelect
                  />
                )}
                control={control}
                name="domaines"
              />
            </Stack.Item>
          )}

          {/* <Stack.Item
            className="ms-Grid-col ms-sm12 ms-lg6 ms-xl3"
            style={{ paddingRight: 20, marginTop: 10 }}
          >
            <Controller
              render={({ field: { name, value } }) => (
                <Dropdown
                  label="Domaine :"
                  selectedKey={value ? (value as any).key : undefined}
                  placeholder="Sélectionnez un domaine"
                  options={domaineList}
                  calloutProps={{ doNotLayer: true }}
                  onChange={(e: any, options: any) => {
                    setValue(name, options, { shouldValidate: true });
                  }}
                  styles={commonInputStyles.dropdownStyle}
                />
              )}
              defaultValue={domaineList[0]}
              control={control}
              name="domaine"
            />
          </Stack.Item> */}
          {showFilters && (
            <>
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Entreprises liées :"
                      placeholder="Recherchez une entreprise (ex : entreprise)"
                      resourceURI={`${API_URL}api/administration/entreprises/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?nom=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.nom}
                      renderValue={(item: any) => item.nom}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      //errorMessage={(errors.ref_idcc as any)?.message}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="entreprises"
                />
              </Stack.Item>

              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectManyFieldAsync
                      label="Organismes de formation liés :"
                      placeholder="Recherchez un OF (ex : OF)"
                      resourceURI={`${API_URL}api/administration/ofs/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?raison_sociale=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.raison_sociale}
                      renderValue={(item: any) => item.raison_sociale}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      //errorMessage={(errors.ref_idcc as any)?.message}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="ofs"
                />
              </Stack.Item>

              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => {
                    return (
                      <Dropdown
                        selectedKey={value ? value.key : undefined}
                        label="Certification :"
                        placeholder="Sélectionnez une certification"
                        options={certificationList}
                        onChange={(e: any, options: any) => {
                          setValue(name, options, { shouldValidate: true });
                        }}
                        styles={commonInputStyles.dropdownStyle}
                      />
                    );
                  }}
                  defaultValue={certificationList[0]}
                  control={control}
                  name="certifications"
                />
              </Stack.Item>
              {!isAnyKindOfAdmin && (
                <Stack.Item
                  className="ms-Grid-col ms-sm12 ms-xl6"
                  style={{ paddingRight: 20, marginTop: 10 }}
                >
                  <Controller
                    render={({ field: { onChange, value } }) => (
                      <Toggle
                        styles={commonInputStyles.toggleInput}
                        label={`${labels.CANDIDATURES_FILTRE_CANDIDATUREACTIONSPART} :`}
                        onText="Oui"
                        offText="Non"
                        onChange={(e, checked) => onChange(checked)}
                        checked={value}
                      />
                    )}
                    defaultValue={false}
                    control={control}
                    name="en_attente_de_ma_part"
                  />
                </Stack.Item>
              )}
              <Stack.Item
                className="ms-Grid-col ms-sm12 ms-xl6"
                style={{ paddingRight: 20, marginTop: 10 }}
              >
                <Controller
                  render={({ field: { name, value, onBlur, ref } }) => (
                    <SelectFieldAsync
                      label="Jury :"
                      placeholder="Recherchez un jury (ex : jury du 3 janvier)"
                      resourceURI={`${API_URL}api/candidature/jurys/chercher`}
                      addQueryURL={(resourceURI: any, terms: any) =>
                        `${resourceURI}?libelle=${terms}`
                      }
                      method="GET"
                      renderItem={(item: any) => item.libelle}
                      renderValue={(item: any) => item.libelle}
                      dataIndex="uuid"
                      name={name}
                      value={value}
                      onBlur={onBlur}
                      fieldRef={ref}
                      onChange={(e: any) => {
                        setValue(name, e.target.value, {
                          shouldValidate: true,
                        });
                      }}
                      //errorMessage={(errors.ref_idcc as any)?.message}
                      handleResponse={(response: any) => [
                        ...response.data.data,
                      ]}
                    />
                  )}
                  control={control}
                  name="jury_associe"
                />
              </Stack.Item>
            </>
          )}
        </Stack>
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="end"
          wrap
        >
          <Stack tokens={{ childrenGap: 15 }} horizontal wrap>
            <Stack.Item>
              <LoadButton isLoading={isLoadingCandidatures} text="Rechercher" />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text={
                  showFilters ? "Recherche simplifiée" : "Recherche avancée"
                }
                onClick={toggleFilters}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton
                text="Réinitialiser ma recherche"
                onClick={onReset}
                styles={commonButtonStyles.defaultButton}
              />
            </Stack.Item>
          </Stack>

          <TextResult total={totalCandidatures} />
        </Stack>
      </form>
    </FormSection>
  );
}

export default CandidatureListFilters;
