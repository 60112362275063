export const branchAccessEnum: any = {
  //param de branche
  ACTIVER_VALIDATION_AVIS_FORMATEUR: "ACTIVER_VALIDATION_AVIS_FORMATEUR",
  INVITER_CANDIDAT_A_L_INSCRIPTION: "INVITER_CANDIDAT_A_L_INSCRIPTION",
  AFFICHER_PARCOURS_CANDIDAT: "AFFICHER_PARCOURS_CANDIDAT",
  ACTIVER_DEMANDE_CREATION_COMPTE_UTILISATEUR:
    "ACTIVER_DEMANDE_CREATION_COMPTE_UTILISATEUR",
  OBLIGER_POSITIONNEMENT: "OBLIGER_POSITIONNEMENT",
  AUTORISER_CREATEUR_CANDIDATURE_DEPOSER_POSITIONNEMENT:
    "AUTORISER_CREATEUR_CANDIDATURE_DEPOSER_POSITIONNEMENT",
  OBLIGER_PARCOURS_CANDIDAT: "OBLIGER_PARCOURS_CANDIDAT",
  OBLIGER_UPLOAD_DERNIER_DIPLOME: "OBLIGER_UPLOAD_DERNIER_DIPLOME",
  OBLIGER_UPLOAD_ATTESTATION_FORMATION_INITIALE:
    "OBLIGER_UPLOAD_ATTESTATION_FORMATION_INITIALE",
  OBLIGER_UPLOAD_ATTESTATION_FORMATION_CONTINUE:
    "OBLIGER_UPLOAD_ATTESTATION_FORMATION_CONTINUE",
  AUTORISER_OF_RENSEIGNER_ENTREPRISE_A_L_INSCRIPTION:
    "AUTORISER_OF_RENSEIGNER_ENTREPRISE_A_L_INSCRIPTION",
  AUTORISER_ENTREPRISE_RENSEIGNER_OF_A_L_INSCRIPTION:
    "AUTORISER_ENTREPRISE_RENSEIGNER_OF_A_L_INSCRIPTION",
  AUTORISER_ENTREPRISE_INVITER_OF_A_L_INSCRIPTION:
    "AUTORISER_ENTREPRISE_INVITER_OF_A_L_INSCRIPTION",
  GENERER_FICHE_INDIVIDUELLE_CANDIDATURE:
    "GENERER_FICHE_INDIVIDUELLE_CANDIDATURE",
  //param de rôle
  OBLIGER_LIAISON_OF_ET_ENTREPRISE: "OBLIGER_LIAISON_OF_ET_ENTREPRISE",
  AUTORISER_CREATEUR_CANDIDATURE_INVITER_POSITIONNEUR:
    "AUTORISER_CREATEUR_CANDIDATURE_INVITER_POSITIONNEUR",
  AUTORISER_CREATEUR_CANDIDATURE_INVITER_EVALUATEUR:
    "AUTORISER_CREATEUR_CANDIDATURE_INVITER_EVALUATEUR",
  OBLIGER_LIAISON_POSITIONNEUR_ET_ENTREPRISE:
    "OBLIGER_LIAISON_POSITIONNEUR_ET_ENTREPRISE",
  OBLIGER_LIAISON_POSITIONNEUR_ET_OF: "OBLIGER_LIAISON_POSITIONNEUR_ET_OF",
  OBLIGER_LIAISON_EVALUATEUR_ET_OF: "OBLIGER_LIAISON_EVALUATEUR_ET_OF",
  OBLIGER_LIAISON_EVALUATEUR_ET_ENTREPRISE:
    "OBLIGER_LIAISON_EVALUATEUR_ET_ENTREPRISE",
  CREATEUR_CANDIDATURE_EFFECTUE_TOUJOURS_EVALUATION:
    "CREATEUR_CANDIDATURE_EFFECTUE_TOUJOURS_EVALUATION",
  POSITIONNEMENT_PAR_COMPETENCE: "POSITIONNEMENT_PAR_COMPETENCE",
  EVALUATION_PAR_CRITERE: "EVALUATION_PAR_CRITERE",
  SUGGESTION_EVALUATION_PRESIDENT_JURY: "SUGGESTION_EVALUATION_PRESIDENT_JURY",
  ACTIVER_AVIS_FORMATEUR: "ACTIVER_AVIS_FORMATEUR",
  AVIS_FORMATEUR_FACULTATIF: "AVIS_FORMATEUR_FACULTATIF",
  CLOTURE_JURY_GENERER_COURRIER_CANDIDAT:
    "CLOTURE_JURY_GENERER_COURRIER_CANDIDAT",
  CLOTURE_JURY_GENERER_COURRIER_ENTREPRISE:
    "CLOTURE_JURY_GENERER_COURRIER_ENTREPRISE",
  CLOTURE_JURY_GENERER_COURRIER_OF: "CLOTURE_JURY_GENERER_COURRIER_OF",
  SUIVI_COHORTES_MODEL_OF_CANDIDAT: "SUIVI_COHORTES_MODEL_OF_CANDIDAT",
  SUIVI_COHORTES_MODEL_OF: "SUIVI_COHORTES_MODEL_OF",
  SUIVI_COHORTES_MODEL_ENTREPRISE_CANDIDAT:
    "SUIVI_COHORTES_MODEL_ENTREPRISE_CANDIDAT",
  SUIVI_COHORTES_MODEL_ENTREPRISE: "SUIVI_COHORTES_MODEL_ENTREPRISE",
  OBLIGER_VALIDATION_BRANCHE_POUR_INSCRIPTION_JURY:
    "OBLIGER_VALIDATION_BRANCHE_POUR_INSCRIPTION_JURY",
  TYPAGE_ENTREPRISE_OF_PAR_CREATEUR_CANDIDATURE:
    "TYPAGE_ENTREPRISE_OF_PAR_CREATEUR_CANDIDATURE",
  TYPAGE_EVALUATEUR_PAR_CREATEUR_CANDIDATURE:
    "TYPAGE_EVALUATEUR_PAR_CREATEUR_CANDIDATURE",
  TYPAGE_POSITIONNEUR_PAR_CREATEUR_CANDIDATURE:
    "TYPAGE_POSITIONNEUR_PAR_CREATEUR_CANDIDATURE",
  OBLIGER_EVALUATEUR_A_LA_CREATION_CANDIDATURE:
    "OBLIGER_EVALUATEUR_A_LA_CREATION_CANDIDATURE",
  PARTIES_PRENANTES_CREATEUR_OF_OU_APPARENTE_LIAISON_ENTREPRISE_A_OF:
    "PARTIES_PRENANTES_CREATEUR_OF_OU_APPARENTE_LIAISON_ENTREPRISE_A_OF",
  PARTIES_PRENANTES_CREATEUR_ENTREPRISE_OU_APPARENTE_LIAISON_OF_A_ENTREPRISE:
    "PARTIES_PRENANTES_CREATEUR_ENTREPRISE_OU_APPARENTE_LIAISON_OF_A_ENTREPRISE",
  PARTIES_PRENANTES_CREATEUR_OF_OU_APPARENTE_LIAISON_OF_AU_POSITIONNEUR:
    "PARTIES_PRENANTES_CREATEUR_OF_OU_APPARENTE_LIAISON_OF_AU_POSITIONNEUR",
  PARTIES_PRENANTES_CREATEUR_ENTREPRISE_OU_APPARENTE_LIAISON_OF_AU_POSITIONNEUR:
    "PARTIES_PRENANTES_CREATEUR_ENTREPRISE_OU_APPARENTE_LIAISON_OF_AU_POSITIONNEUR",
  PARTIES_PRENANTES_CREATEUR_OF_OU_APPARENTE_LIAISON_OF_A_EVALUATEUR:
    "PARTIES_PRENANTES_CREATEUR_OF_OU_APPARENTE_LIAISON_OF_A_EVALUATEUR",
  PARTIES_PRENANTES_CREATEUR_ENTREPRISE_OU_APPARENTE_LIAISON_ENTREPRISE_A_EVALUATEUR:
    "PARTIES_PRENANTES_CREATEUR_ENTREPRISE_OU_APPARENTE_LIAISON_ENTREPRISE_A_EVALUATEUR",
  ACCES_DOCUMENT_BRANCHE_AVANT_DECISION_FINALE:
    "ACCES_DOCUMENT_BRANCHE_AVANT_DECISION_FINALE",
  CANDIDAT_EST_PARTIE_PRENANTE_PROCESSUS_CERTIFICATION:
    "CANDIDAT_EST_PARTIE_PRENANTE_PROCESSUS_CERTIFICATION",
  POSSIBILITE_D_INVITER_UN_SECOND_EVALUATEUR:
    "POSSIBILITE_D_INVITER_UN_SECOND_EVALUATEUR",
  OBLIGER_AVIS_SECOND_EVALUATEUR: "OBLIGER_AVIS_SECOND_EVALUATEUR",
  ACTIVER_PRISE_CONNAISSANCE_EVALUATION:
    "ACTIVER_PRISE_CONNAISSANCE_EVALUATION",
  OBLIGER_COMMENTAIRE_JURY_PAR_CANDIDATURE:
    "OBLIGER_COMMENTAIRE_JURY_PAR_CANDIDATURE",
  HABILITATION_SIMPLE_OF: "HABILITATION_SIMPLE_OF",
  HABILITATION_STRICTE_EVALUATEUR: "HABILITATION_STRICTE_EVALUATEUR",
  HABILITATION_STRICTE_POSITIONNEUR: "HABILITATION_STRICTE_POSITIONNEUR",
  RENDRE_IDCC_FACULTATIF_SUR_LES_ENTREPRISES:
    "RENDRE_IDCC_FACULTATIF_SUR_LES_ENTREPRISES",
  LA_BRANCHE_AUTORISE_LA_CREATION_DE_STRUCTURE:
    "LA_BRANCHE_AUTORISE_LA_CREATION_DE_STRUCTURE",
  ACTIVER_DEMANDE_HABILITATION: "ACTIVER_DEMANDE_HABILITATION",
};

export const branchAccessMessageEnum: any = {
  INVITER_CANDIDAT_A_L_INSCRIPTION:
    "En raison du paramétrage de cette branche, le candidat doit être invité à renseigner lui-même ses informations.",
  AUTORISER_OF_RENSEIGNER_ENTREPRISE_A_L_INSCRIPTION:
    "Les paramètres des la branche n'autorisent pas l'OF à créer une entreprise lors de la saisie des parties prenantes.",
  AUTORISER_ENTREPRISE_RENSEIGNER_OF_A_L_INSCRIPTION:
    "Les paramètres des la branche n'autorisent pas l'entreprise à créer un OF lors de la saisie des parties prenantes.",
  AUTORISER_ENTREPRISE_INVITER_OF_A_L_INSCRIPTION:
    "Les paramètres des la branche n'autorisent pas l'entreprise à inviter un utilisateur OF lors de la saisie des parties prenantes.",
  CREATEUR_CANDIDATURE_EFFECTUE_TOUJOURS_EVALUATION:
    "Les paramètres de la branche obligent le créateur de la candidature à être l'évaluateur.",
};

export const userAccessEnum: any = {
  PARAMETRER_HABILITATIONS: "PARAMETRER_HABILITATIONS",
  CREER_UTILISATEUR: "CREER_UTILISATEUR", //done
  GERER_UTILISATEUR: "GERER_UTILISATEUR", //done
  DEMANDER_INSCRIPTION_HORS_CONNEXION: "DEMANDER_INSCRIPTION_HORS_CONNEXION",
  DEMANDER_INSCRIPTION_VIA_INVITATION: "DEMANDER_INSCRIPTION_VIA_INVITATION",
  INSCRIPTION_CONNEXION_DIRECTE: "INSCRIPTION_CONNEXION_DIRECTE",
  VISUALISER_SOUS_GROUPE: "VISUALISER_SOUS_GROUPE",
  CREER_SOUS_GROUPE: "CREER_SOUS_GROUPE",
  MODIFIER_SOUS_GROUPE: "MODIFIER_SOUS_GROUPE",
  VISUALISER_UTILISATEUR_SOUS_GROUPE: "VISUALISER_UTILISATEUR_SOUS_GROUPE",
  CREER_UTILISATEUR_SOUS_GROUPE: "CREER_UTILISATEUR_SOUS_GROUPE",
  DESACTIVER_UTILISATEUR_SOUS_GROUPE: "DESACTIVER_UTILISATEUR_SOUS_GROUPE",
  MODIFIER_UTILISATEUR_SOUS_GROUPE: "MODIFIER_UTILISATEUR_SOUS_GROUPE",
  VISUALISER_DEMANDE_INSCRIPTION_UTILISATEUR:
    "VISUALISER_DEMANDE_INSCRIPTION_UTILISATEUR",
  VALIDER_DEMANDE_INSCRIPTION_UTILISATEUR:
    "VALIDER_DEMANDE_INSCRIPTION_UTILISATEUR",
  VISUALISER_ACCUEIL_APRES_CONNEXION: "VISUALISER_ACCUEIL_APRES_CONNEXION",
  SAISIR_1ER_INFO_CANDIDATURE: "SAISIR_1ER_INFO_CANDIDATURE",
  INVITER_CANDIDAT_CREATION_CANDIDATURE:
    "INVITER_CANDIDAT_CREATION_CANDIDATURE",
  SAISIR_PROFIL_CANDIDAT: "SAISIR_PROFIL_CANDIDAT",
  SAISIR_CANDIDATURE: "SAISIR_CANDIDATURE", //done
  IMPORT_CANDIDATURE: "IMPORT_CANDIDATURE", //done
  SAISIR_PARCOURS_CANDIDAT: "SAISIR_PARCOURS_CANDIDAT", //done
  CHOIX_BLOCS_CERTIF: "CHOIX_BLOCS_CERTIF", //done
  IDENTIFICATION_PARTIES_PRENANTES_PARCOURS:
    "IDENTIFICATION_PARTIES_PRENANTES_PARCOURS", //done
  TRANSMISSION_DOSSIER_CANDIDATURE: "TRANSMISSION_DOSSIER_CANDIDATURE", //done
  ABANDON_CANDIDATURES_MAX_STATUT_ATTENTE_POSITIONNEMENT:
    "ABANDON_CANDIDATURES_MAX_STATUT_ATTENTE_POSITIONNEMENT",
  ABANDON_CANDIDATURES_MAX_PASSAGE_JURY:
    "ABANDON_CANDIDATURES_MAX_PASSAGE_JURY",
  VISUALISER_ABANDON_CANDIDATURES: "VISUALISER_ABANDON_CANDIDATURES",
  VISUALISER_TABLEAU_BORD_CANDIDATURES: "VISUALISER_TABLEAU_BORD_CANDIDATURES",
  EXPORTER_INFORMATION_CANDIDATURES_EXCEL:
    "EXPORTER_INFORMATION_CANDIDATURES_EXCEL",
  VISUALISER_PROFIL_CANDIDAT: "VISUALISER_PROFIL_CANDIDAT",
  MODIFIER_PROFIL_CANDIDAT_AVANT_CERTIF:
    "MODIFIER_PROFIL_CANDIDAT_AVANT_CERTIF",
  MODIFIER_PROFIL_CANDIDAT_APRES_CERTIF:
    "MODIFIER_PROFIL_CANDIDAT_APRES_CERTIF", //done
  VISUALISER_CANDIDATURE: "VISUALISER_CANDIDATURE", //done
  MODIFIER_INFO_CANDIDATURE_AVANT_CERTIF:
    "MODIFIER_INFO_CANDIDATURE_AVANT_CERTIF",
  MODIFIER_INFO_CANDIDATURE_APRES_CERTIF:
    "MODIFIER_INFO_CANDIDATURE_APRES_CERTIF",
  VISUALISER_PARCOURS_CANDIDAT: "VISUALISER_PARCOURS_CANDIDAT", //done
  MODIFIER_PARCOURS_CANDIDAT: "MODIFIER_PARCOURS_CANDIDAT",
  VISUALISER_CHOIX_BLOCS_CERTIF: "VISUALISER_CHOIX_BLOCS_CERTIF", //done
  MODIFIER_CHOIX_BLOCS_CERTIF: "MODIFIER_CHOIX_BLOCS_CERTIF", //done
  VISUALISER_IDENTIFICATION_PARTIES_PRENANTES_PARCOURS:
    "VISUALISER_IDENTIFICATION_PARTIES_PRENANTES_PARCOURS", //done
  MODIFIER_UTILISATEURS_RATTACHES_CANDIDATURE_MAX_INTERVENTION:
    "MODIFIER_UTILISATEURS_RATTACHES_CANDIDATURE_MAX_INTERVENTION",
  MODIFIER_EVALUATEUR_RATTACHE_CANDIDATURE_MAX_VALIDATION:
    "MODIFIER_EVALUATEUR_RATTACHE_CANDIDATURE_MAX_VALIDATION",
  PARAMETRER_CANDIDATURES: "PARAMETRER_CANDIDATURES",
  VISUALISATION_FICHE_CANDIDAT: "VISUALISATION_FICHE_CANDIDAT",
  SAISIR_POSITIONNEMENT: "SAISIR_POSITIONNEMENT", //done
  MODIFIER_POSITIONNEMENT_AVANT_EVALUATION_OU_AVIS:
    "MODIFIER_POSITIONNEMENT_AVANT_EVALUATION_OU_AVIS", //done
  VISUALISER_POSITIONNEMENT: "VISUALISER_POSITIONNEMENT", //done
  SAISIR_AVIS_CANDIDAT_POSITIONNEMENT: "SAISIR_AVIS_CANDIDAT_POSITIONNEMENT", //done
  MODIFIER_AVIS_CANDIDAT_POSITIONNEMENT:
    "MODIFIER_AVIS_CANDIDAT_POSITIONNEMENT", //done
  VISUALISER_AVIS_CANDIDAT_POSITIONNEMENT:
    "VISUALISER_AVIS_CANDIDAT_POSITIONNEMENT", //done
  PARAMETRER_POSITIONNEMENT: "PARAMETRER_POSITIONNEMENT", //done
  CREER_AVIS_FORMATEUR: "CREER_AVIS_FORMATEUR", //done
  MODIFIER_AVIS_FORMATEUR: "MODIFIER_AVIS_FORMATEUR", //done
  VISUALISER_AVIS_FORMATEUR: "VISUALISER_AVIS_FORMATEUR", //done
  VALIDER_AVIS_FORMATEUR: "VALIDER_AVIS_FORMATEUR",
  SAISIR_EVALUATION: "SAISIR_EVALUATION", //done
  MODIFIER_EVALUATION_APRES_TRANSMISSION:
    "MODIFIER_EVALUATION_APRES_TRANSMISSION", //done
  VISUALISER_EVALUATION: "VISUALISER_EVALUATION", //done
  INVITER_SECOND_EVALUATEUR: "INVITER_SECOND_EVALUATEUR", //done
  SAISIR_AVIS_SECOND_EVALUATEUR: "SAISIR_AVIS_SECOND_EVALUATEUR", //done
  MODIFIER_AVIS_SECOND_EVALUATEUR: "MODIFIER_AVIS_SECOND_EVALUATEUR", //done
  VISUALISER_AVIS_SECOND_EVALUATEUR: "VISUALISER_AVIS_SECOND_EVALUATEUR", //done
  SAISIR_VALIDATION_EVALUATION: "SAISIR_VALIDATION_EVALUATION", //done
  VISUALISATION_VALIDATION_EVALUATION: "VISUALISATION_VALIDATION_EVALUATION", //done
  PARAMETRER_EVALUATION: "PARAMETRER_EVALUATION",
  VISUALISER_JURYS: "VISUALISER_JURYS",
  CREER_JURYS_CERTIFICATION: "CREER_JURYS_CERTIFICATION",
  VISUALISER_JURY: "VISUALISER_JURY",
  INSCRIRE_AU_JURY_CERTIFICATION: "INSCRIRE_AU_JURY_CERTIFICATION",
  VALIDER_INSCRIPTIONS_JURY: "VALIDER_INSCRIPTIONS_JURY",
  SAISIR_AVIS_JURY: "SAISIR_AVIS_JURY", //done
  VISUALISER_AVIS_JURY: "VISUALISER_AVIS_JURY",
  CREER_DECISION_JURY: "CREER_DECISION_JURY",
  VISUALISER_DECISIONS_JURY_PAR_CANDIDATURE:
    "VISUALISER_DECISIONS_JURY_PAR_CANDIDATURE",
  CLOTURER_JURY: "CLOTURER_JURY",
  SUPPRIMER_JURY: "SUPPRIMER_JURY",
  PARAMETRER_JURY: "PARAMETRER_JURY",
  SAISIR_SUIVI_COHORTES: "SAISIR_SUIVI_COHORTES",
  VISUALISER_TITULAIRES_A_SUIVRE: "VISUALISER_TITULAIRES_A_SUIVRE",
  MODIFIER_SUIVI_COHORTES: "MODIFIER_SUIVI_COHORTES",
  EXPORTER_SUIVI_COHORTES_FRANCE_COMPETENCES:
    "EXPORTER_SUIVI_COHORTES_FRANCE_COMPETENCES",
  EXPORTER_XML: "EXPORTER_XML",
  ACCEDER_DUPLICATA_PARCHEMIN: "ACCEDER_DUPLICATA_PARCHEMIN",
  TELECHARGER_DUPLICATA_FICHE_DECISION: "TELECHARGER_DUPLICATA_FICHE_DECISION",
  TELECHARGER_PV_JURY: "TELECHARGER_PV_JURY",
  TELECHARGER_PARCHEMIN: "TELECHARGER_PARCHEMIN",
  TELECHARGER_FICHE_DECISION: "TELECHARGER_FICHE_DECISION",
  TELECHARGER_ATTESTATIONS_BLOCS_COMPETENCES:
    "TELECHARGER_ATTESTATIONS_BLOCS_COMPETENCES",
  TELECHARGER_MODELE_LETTRES_OF: "TELECHARGER_MODELE_LETTRES_OF",
  TELECHARGER_MODELE_LETTRES_ENTREPRISES:
    "TELECHARGER_MODELE_LETTRES_ENTREPRISES",
  TELECHARGER_MODELE_LETTRES_CANDIDAT: "TELECHARGER_MODELE_LETTRES_CANDIDAT",
  TELECHARGER_DOCUMENTS_JURY: "TELECHARGER_DOCUMENTS_JURY",
  ACCEDER_REPORTING_STAT: "ACCEDER_REPORTING_STAT",
  EXPORTER_UTILISATEURS_ET_ENTITES: "EXPORTER_UTILISATEURS_ET_ENTITES",
  PARAMETRER_MATRICE_ACL: "PARAMETRER_MATRICE_ACL",
  PARAMETRER_BRANCHE: "PARAMETRER_BRANCHE",
  VISUALISER_INFORMATION_CREATEUR: "VISUALISER_INFORMATION_CREATEUR",
  DEMANDER_AIDE_BRANCHE: "DEMANDER_AIDE_BRANCHE",
  PARAMETRER_CHAMPS_COMPLEMENTAIRES: "PARAMETRER_CHAMPS_COMPLEMENTAIRES",
  PARAMETRER_PAGES_STATIQUES: "PARAMETRER_PAGES_STATIQUES",
  VISUALISER_PAGES_STATIQUES: "VISUALISER_PAGES_STATIQUES",
  PARAMETRER_COMPETENCES: "PARAMETRER_COMPETENCES",
  PARAMETRER_BLOCS_COMPETENCES: "PARAMETRER_BLOCS_COMPETENCES",
  PARAMETRER_CERTIFICATIONS: "PARAMETRER_CERTIFICATIONS",
  PARAMETRER_ENTREPRISES_OF_GROUPES: "PARAMETRER_ENTREPRISES_OF_GROUPES",
  VISUALISER_CATALOGUE_CERTIFICATIONS: "VISUALISER_CATALOGUE_CERTIFICATIONS",
  PARAMETRER_LIBELLES: "PARAMETRER_LIBELLES",
  MODIFIER_INFORMATION_MON_COMPTE: "MODIFIER_INFORMATION_MON_COMPTE",
  MODIFIER_INFORMATION_MA_STRUCTURE: "MODIFIER_INFORMATION_MA_STRUCTURE",
  DESIGNER_COMPTE_REFERENT: "DESIGNER_COMPTE_REFERENT",
  VISUALISER_COMPTES_REFERENTS: "VISUALISER_COMPTES_REFERENTS",
  VALIDER_EVALUATION: "VALIDER_EVALUATION",
  CHANGER_DEMANDE_INSCRIPTION_EN_MASSE: "CHANGER_DEMANDE_INSCRIPTION_EN_MASSE",
  NO_ACCESS: "NO_ACCESS", //aucun utilisateur ne peut avoir ce rôle, il est utilisé pour bloquer des accès dans certaines conditions sauf pour les admins
  NO_ACCESS_EVEN_ADMIN: "NO_ACCESS_EVEN_ADMIN", //aucun utilisateur ne peut avoir ce rôle, il est utilisé pour bloquer des accès dans certaines conditions
  EXPORTER_CANDIDATURE_JURY: "EXPORTER_CANDIDATURE_JURY",
  // VISUALISER_DEMANDE_INSCRIPTION_STRUCTURE:
  //   "VISUALISER_DEMANDE_INSCRIPTION_STRUCTURE",
  // VALIDER_DEMANDE_INSCRIPTION_STRUCTURE:
  //   "VALIDER_DEMANDE_INSCRIPTION_STRUCTURE",
};
export const allAccess = () => {
  const accesses = [];
  for (var key in userAccessEnum) {
    accesses.push(userAccessEnum[key]);
  }
  for (var key in branchAccessEnum) {
    accesses.push(branchAccessEnum[key]);
  }
  return accesses;
};

export const permissionsByCandidatureStatus: any = {
  creation_candidature: [
    userAccessEnum.SAISIR_CANDIDATURE,
    userAccessEnum.INVITER_CANDIDAT_CREATION_CANDIDATURE,
    userAccessEnum.SAISIR_1ER_INFO_CANDIDATURE,
    userAccessEnum.VISUALISER_CANDIDATURE,
  ],
  saisie_informations: [
    userAccessEnum.SAISIR_CANDIDATURE,
    userAccessEnum.VISUALISER_CANDIDATURE,
  ],
  saisie_parcours: [
    userAccessEnum.SAISIR_PARCOURS_CANDIDAT,
    userAccessEnum.MODIFIER_PARCOURS_CANDIDAT,
    userAccessEnum.VISUALISER_PARCOURS_CANDIDAT,
    userAccessEnum.VISUALISER_CANDIDATURE,
  ],
  choix_blocs: [
    userAccessEnum.CHOIX_BLOCS_CERTIF,
    userAccessEnum.VISUALISER_CHOIX_BLOCS_CERTIF,
    userAccessEnum.MODIFIER_CHOIX_BLOCS_CERTIF,
    userAccessEnum.VISUALISER_CANDIDATURE,
  ],
  identification_parties_prenantes: [
    userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS,
    userAccessEnum.VISUALISER_IDENTIFICATION_PARTIES_PRENANTES_PARCOURS,
    userAccessEnum.VISUALISER_CANDIDATURE,
  ],
  transmission_dossier: [
    userAccessEnum.TRANSMISSION_DOSSIER_CANDIDATURE,
    userAccessEnum.VISUALISER_CANDIDATURE,
  ],
  validation_vae: [],
  positionnement: [
    userAccessEnum.SAISIR_POSITIONNEMENT,
    userAccessEnum.MODIFIER_POSITIONNEMENT_AVANT_EVALUATION_OU_AVIS,
    userAccessEnum.VISUALISER_POSITIONNEMENT,
  ],
  validation_positionnement: [
    userAccessEnum.SAISIR_AVIS_CANDIDAT_POSITIONNEMENT,
    userAccessEnum.MODIFIER_AVIS_CANDIDAT_POSITIONNEMENT,
    userAccessEnum.VISUALISER_AVIS_CANDIDAT_POSITIONNEMENT,
  ],
  avis_formateur: [
    userAccessEnum.CREER_AVIS_FORMATEUR,
    userAccessEnum.MODIFIER_AVIS_FORMATEUR,
    userAccessEnum.VISUALISER_AVIS_FORMATEUR,
  ],
  valider_avis_formateur: [
    userAccessEnum.VISUALISER_AVIS_FORMATEUR,
    userAccessEnum.VALIDER_AVIS_FORMATEUR,
  ],
  evaluation: [
    userAccessEnum.SAISIR_EVALUATION,
    userAccessEnum.MODIFIER_EVALUATION_APRES_TRANSMISSION,
    userAccessEnum.VISUALISER_EVALUATION,
    userAccessEnum.INVITER_SECOND_EVALUATEUR,
  ],
  avis_second_evaluateur: [
    userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR,
    userAccessEnum.MODIFIER_AVIS_SECOND_EVALUATEUR,
    userAccessEnum.VISUALISER_AVIS_SECOND_EVALUATEUR,
  ],
  valider_evaluation: [
    userAccessEnum.VALIDER_EVALUATION,
    userAccessEnum.VISUALISATION_VALIDATION_EVALUATION,
  ],
  inscription_jury: [userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION],

  decision_jury: [
    userAccessEnum.SAISIR_AVIS_JURY,
    userAccessEnum.VISUALISER_AVIS_JURY,
    //userAccessEnum.CREER_DECISION_JURY,
    //le jury est compose uniquement de membre qui ne possede pas la permission CREER_DECISION_JURY
  ],
  resultats_jury: [userAccessEnum.VISUALISER_DECISIONS_JURY_PAR_CANDIDATURE],
};

export const permissionsEditCandidatureStatus: any = {
  creation_candidature: [
    userAccessEnum.SAISIR_CANDIDATURE,
    userAccessEnum.INVITER_CANDIDAT_CREATION_CANDIDATURE,
    userAccessEnum.SAISIR_1ER_INFO_CANDIDATURE,
  ],
  saisie_informations: [userAccessEnum.SAISIR_CANDIDATURE],
  saisie_parcours: [
    userAccessEnum.SAISIR_PARCOURS_CANDIDAT,
    userAccessEnum.MODIFIER_PARCOURS_CANDIDAT,
  ],
  choix_blocs: [
    userAccessEnum.CHOIX_BLOCS_CERTIF,
    userAccessEnum.MODIFIER_CHOIX_BLOCS_CERTIF,
  ],
  identification_parties_prenantes: [
    userAccessEnum.IDENTIFICATION_PARTIES_PRENANTES_PARCOURS,
  ],
  transmission_dossier: [userAccessEnum.TRANSMISSION_DOSSIER_CANDIDATURE],
  validation_vae: [],
  positionnement: [
    userAccessEnum.SAISIR_POSITIONNEMENT,
    userAccessEnum.MODIFIER_POSITIONNEMENT_AVANT_EVALUATION_OU_AVIS,
  ],
  validation_positionnement: [
    userAccessEnum.SAISIR_AVIS_CANDIDAT_POSITIONNEMENT,
    userAccessEnum.MODIFIER_AVIS_CANDIDAT_POSITIONNEMENT,
  ],
  avis_formateur: [
    userAccessEnum.CREER_AVIS_FORMATEUR,
    userAccessEnum.MODIFIER_AVIS_FORMATEUR,
  ],
  valider_avis_formateur: [userAccessEnum.VALIDER_AVIS_FORMATEUR],
  evaluation: [
    userAccessEnum.SAISIR_EVALUATION,
    userAccessEnum.MODIFIER_EVALUATION_APRES_TRANSMISSION,
    userAccessEnum.INVITER_SECOND_EVALUATEUR,
    userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR,
    userAccessEnum.MODIFIER_AVIS_SECOND_EVALUATEUR,
  ],
  avis_second_evaluateur: [
    userAccessEnum.SAISIR_AVIS_SECOND_EVALUATEUR,
    userAccessEnum.MODIFIER_AVIS_SECOND_EVALUATEUR,
  ],
  valider_evaluation: [userAccessEnum.VALIDER_EVALUATION],
  inscription_jury: [userAccessEnum.INSCRIRE_AU_JURY_CERTIFICATION],
  decision_jury: [
    userAccessEnum.SAISIR_AVIS_JURY,
    //userAccessEnum.CREER_DECISION_JURY,
    //le jury est compose uniquement de membre qui ne possede pas la permission CREER_DECISION_JURY
  ],
  resultats_jury: [userAccessEnum.VISUALISER_DECISIONS_JURY_PAR_CANDIDATURE],
};

export const documentsPermissionsLabels: any = {
  PARCHEMIN: {
    nom: "Parchemin",
    permission: userAccessEnum.TELECHARGER_PARCHEMIN,
    label: "FICHE_INDIVIDUELLE_CERTIFICATION_LIEN_FICHE_SYNTHETIQUE_PAR_CHEMIN",
  },
  PARCHEMIN_DUPLICATA: {
    nom: "Pachemin duplicata",
    permission: userAccessEnum.ACCEDER_DUPLICATA_PARCHEMIN,
    label: "FICHE_INDIVIDUELLE_CERTIFICATION_LIEN_FICHE_DUPLICATA_PAR_CHEMIN",
  },
  PARCHEMIN_CERTIFICATION_DELIVREE: {
    nom: "Parchemin certification délivrée",
    permission: userAccessEnum.TELECHARGER_PARCHEMIN,
  }, //verifier si permission idem parchemin
  PARCHEMIN_CERTIFICATION_DELIVREE_DUPLICATA: {
    nom: "Parchemin certification délivrée duplicata",
    permission: userAccessEnum.ACCEDER_DUPLICATA_PARCHEMIN,
  }, //verifier si permission idem parchemin duplicata
  FICHE_DECISION: {
    nom: "Fiche de décision",
    permission: userAccessEnum.TELECHARGER_FICHE_DECISION,
  },
  FICHE_DECISION_DUPLICATA: {
    nom: "Fiche de décision duplicata",
    permission: userAccessEnum.TELECHARGER_DUPLICATA_FICHE_DECISION,
  },
};
