import { IconButton, Stack } from "@fluentui/react";
import { useLocation } from "react-router-dom";
import SideNavItem from "./SideNavItem";
import { ISideNavProps } from "../../interfaces/sidenavInterface";
import sideNavStyles from "./SideNav.styles";
import useAuth from "../../hooks/useAuth";
import useNav, { isOfflineAtom } from "../../hooks/useNav";
import useTheme from "../useTheme";
import useAccessSettings from "../../hooks/branchSettings/useAccessSettings";
import { atom, useAtom } from "jotai";
import { useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { userSelectedBranchAtom } from "../../atoms/branchAtom";
import tools from "../../utils/tools";
import { routeGroups } from "../../config/routesConfig";
import Accordion from "../Accordion/Accordion";
import { accordionMenuStyles } from "../Accordion/Accordion.styles";
import useWindowSize from "../../hooks/useWindowSize/useWindowSize";
import { API_URL } from "../../config/config";
import { IRoute } from "../../interfaces/routeInterface";
import { atomWithStorage } from "jotai/utils";
import { branchAccessEnum } from "../../config/accessEnum";
import useInfoProfilUser from "../../hooks/useInfoProfilUser";

export const isSidenavOpenAtom = atom(true);
const isReducedAtom = atomWithStorage("isSidenavReduced", false);

function SideNav({ routes }: ISideNavProps) {
  const [isSidenavOpen, setIsSidenavOpen] = useAtom(isSidenavOpenAtom);
  const [isOffline] = useAtom(isOfflineAtom);
  const { primaryColor } = useTheme();
  const { auth } = useAuth();
  const { pathname } = useLocation();
  const { isNavOpen, actions: actionsNav } = useNav();
  const { actions: accessActions } = useAccessSettings();
  const [userSelectedBranch]: any = useAtom(userSelectedBranchAtom);
  const windowSize: any = useWindowSize();
  const [isReduced, setIsReduced] = useAtom(isReducedAtom);
  const { isAnyKindOfAdmin } = useInfoProfilUser();

  useEffect(() => {
    let container = document.querySelector(".contentContainer");
    if (isSidenavOpen) {
      container?.classList.add("minimized");
    } else {
      container?.classList.remove("minimized");
    }
  }, [isSidenavOpen]);

  useEffect(() => {
    let container = document.querySelector(".contentContainer");
    if (isReduced) {
      container?.classList.add("reduced");
    } else {
      container?.classList.remove("reduced");
    }
  }, [isReduced]);

  useEffect(() => {
    if (windowSize.width <= 1023) {
      if (isSidenavOpen) {
        setIsSidenavOpen(false);
        setIsReduced(false);
      }
    } else {
      if (!isSidenavOpen) {
        setIsSidenavOpen(true);
      }
    }
  }, [windowSize.width]);

  const toggleSidenavOpen = (isOpen: any) => {
    if (isOpen && document.body.clientWidth <= 1023) {
    } else {
      setIsSidenavOpen(isOpen);
    }
  };

  const canIAccessRoute = (route: any) => {
    if (isOffline) return false;
    let canAccess =
      route.inNavbar && accessActions.isRouteAccessAllowed(route.allowedAccess);
    if (route.allowedProfiles) {
      if (!auth.user.est_administrateur) {
        const myProfiles = (
          tools.findIn(
            auth.user.mes_branches,
            userSelectedBranch.id,
            "id"
          ) as any
        ).profils;
        canAccess =
          canAccess &&
          myProfiles?.some((r: any) => r.includes(route.allowedProfiles));
      }
    }
    if (route.showWithDomainSystem) {
      canAccess = canAccess && userSelectedBranch.gestion_par_domaine;
    }
    if (route.showSuiviCohorte) {
      canAccess =
        canAccess &&
        (accessActions.canI({
          action: branchAccessEnum.SUIVI_COHORTES_MODEL_OF_CANDIDAT,
        }).granted ||
          accessActions.canI({
            action: branchAccessEnum.SUIVI_COHORTES_MODEL_OF,
          }).granted ||
          accessActions.canI({
            action: branchAccessEnum.SUIVI_COHORTES_MODEL_ENTREPRISE_CANDIDAT,
          }).granted);
    }
    if (route.showIsReferentUserOnly) {
      canAccess = canAccess && (isAnyKindOfAdmin || auth.isReferent);
    }
    if (route.habilitationAccess) {
      canAccess =
        canAccess &&
        accessActions.canI({
          action: route.habilitationAccess,
        }).granted;
    }
    if (route.demandeHabilitationAccess) {
      canAccess =
        canAccess &&
        accessActions.canI({
          action: route.demandeHabilitationAccess,
        }).granted;
    }
    if (route.showForUserCanHaveHabilitation) {
      const roleWithParamHabilitationAssociate = [
        { role: "OF", parametre: branchAccessEnum.HABILITATION_SIMPLE_OF },
        {
          role: "POSITIONNEUR",
          parametre: branchAccessEnum.HABILITATION_STRICTE_POSITIONNEUR,
        },
        {
          role: "EVALUATEUR",
          parametre: branchAccessEnum.HABILITATION_STRICTE_EVALUATEUR,
        },
      ];
      const canShowMyHabilitation = roleWithParamHabilitationAssociate.map(
        (item: any) =>
          userSelectedBranch.profils.includes(item.role) &&
          accessActions.canI({
            action: item.parametre,
          }).granted
      );
      canAccess =
        canAccess &&
        canShowMyHabilitation.some((isAccessible: any) => isAccessible);
    }
    return canAccess;
  };

  // isPublicRoute || !isAuth ||
  if (!userSelectedBranch) {
    return null;
  }

  return (
    <Stack
      styles={sideNavStyles.outerStyle}
      style={
        {
          "--primary-color": primaryColor,
        } as React.CSSProperties
      }
      className={`sideNavContainer ${
        isNavOpen ? "sideNavContainer-isOpen" : ""
      } ${isSidenavOpen ? "sideNavContainer-isOpenFull" : ""} 
      ${isReduced ? "reduced" : ""}
      
      `}
      horizontalAlign="center"
      // onMouseEnter={() => toggleSidenavOpen(true)}
      // onMouseLeave={() => toggleSidenavOpen(false)}
    >
      {isNavOpen && (
        <Stack
          horizontal
          horizontalAlign="start"
          style={{ width: "100%", marginTop: 5, marginLeft: 5 }}
        >
          <IconButton
            className="ms-hiddenXlUp"
            style={{ padding: "0.5rem 1re m" }}
            iconProps={{ iconName: "Cancel" }}
            title="nav"
            ariaLabel="nav"
            onClick={actionsNav.toggleNav}
          />
        </Stack>
      )}
      <Stack
        horizontalAlign="center"
        className="site-name"
        styles={sideNavStyles.brandStyle}
        style={{
          opacity: isReduced ? "0" : "1",
        }}
      >
        Certifi@
      </Stack>
      {/* {isSidenavOpen && (
        <DefaultButton
          onClick={() => setIsReduced(!isReduced)}
          style={{
            position: "absolute",
            top: 10,
            right: 17,
            zIndex: 100,
            padding: "0 5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            border: "none",
            minWidth: 0,
            minHeight: 0,
            color: primaryColor,
          }}
        >
          {isReduced ? (
            <IoIosArrowDroprightCircle
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          ) : (
            <IoIosArrowDropleftCircle
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          )}
        </DefaultButton>
      )} */}

      {["romain@10positif.com", "joan@10positif.com"].includes(
        auth.user.email
      ) && (
        <>
          <a
            style={{ textAlign: "right", padding: "0 20px" }}
            target="_blank"
            href={`${API_URL}horizon`}
            rel="noreferrer"
          >
            Horizon
          </a>
        </>
      )}

      <Stack
        horizontalAlign="center"
        className="site-burger"
        styles={sideNavStyles.brandStyle}
        //onMouseEnter={() => toggleSidenavOpen(!isSidenavOpen)}
      >
        <GiHamburgerMenu />
      </Stack>
      {/* <Stack
        horizontalAlign="center"
        className="site-close"
        styles={sideNavStyles.brandStyle}
        onClick={() => toggleSidenavOpen(!isSidenavOpen)}
      >
        <IoMdClose />
      </Stack> */}
      <ul
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          listStyleType: "none",
          paddingInlineStart: 0,
        }}
      >
        {routes
          .filter((route) => {
            return isOffline && route.visibleOffline
              ? true
              : !route.routeGroup
              ? canIAccessRoute(route)
              : false;
            // return !route.routeGroup ? canIAccessRoute(route) : false;
            // return isOffline;
            // return !route.routeGroup ? canIAccessRoute(route) : false;
          })
          .map((route) => {
            return (
              <SideNavItem
                key={`sidenavItems${route.title}`}
                isActive={pathname.includes(route.path)}
                isReduced={isReduced}
                {...route}
              />
            );
          })}

        {Object.keys(routeGroups).map((groupKey: any) => {
          const groupRef = routeGroups[groupKey];
          if (isOffline && !routeGroups[groupKey].visibleOffline) return null;
          if (!accessActions.isRouteAccessAllowed(groupRef.allowedAccess))
            return null;
          return (
            <Accordion
              key={`routegroup${groupKey}`}
              defaultIsOpen={groupRef.startOpen}
              title={
                <div
                  className="sideNavItem accordionTitle"
                  style={{ color: "#666666" }}
                >
                  <span className="sideNavItemIcon d-center">
                    {groupRef.icon}
                  </span>
                  {!isReduced && (
                    <span className="sideNavItemText">{groupRef.name}</span>
                  )}
                </div>
              }
              styles={accordionMenuStyles(primaryColor)}
              content={
                <ul
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    listStyleType: "none",
                    paddingInlineStart: 0,
                  }}
                >
                  {routes
                    .filter(
                      (route: IRoute) =>
                        route.routeGroup?.id === groupRef.id &&
                        canIAccessRoute(route)
                    )
                    .map((route: any) => (
                      <SideNavItem
                        key={`sidenavItems${route.title}`}
                        isActive={pathname.includes(route.path)}
                        isReduced={isReduced}
                        {...route}
                      />
                    ))}
                </ul>
              }
              srcImg=""
              altImg=""
              noImg
            />
          );
        })}
      </ul>
    </Stack>
  );
}

export default SideNav;
