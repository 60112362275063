import { axiosInstance } from "../config/axiosConfig";
import tools from "../utils/tools";
import { getCSRFCookieRequest } from "./authServices";

const eCertifApi = axiosInstance;

const candidatureURI = "candidature/candidatures";

const candidatureJuryfilterType = {
  nom: { type: "string" },
  prenom: { type: "string" },
  resultat: { type: "array", keyValue: "key", valueNotFilter: 0 },
  //domaine: { type: "select", keyValue: "key", valueNotFilter: 0 },
  entreprises: { type: "array", keyValue: "uuid" },
  ofs: { type: "array", keyValue: "uuid" },
  certifications: { type: "array", keyValue: "uuid" },
  jury_associe: { type: "string" }, // a modifie -> add isWaitingMe en fonction toggle or select
};

export const getCandidaturesRequest = async (filters: any, options: any) => {
  // a modifie -> add userId - mes candidatures
  const filterType = {
    nom: { type: "string" },
    prenom: { type: "string" },
    statut: { type: "select", keyValue: "key", valueNotFilter: 0 },
    statuts: { type: "array", keyValue: "key", valueNotFilter: 0 },
    resultat: { type: "string", keyUrl: "resultat[]" },
    //domaine: { type: "select", keyValue: "key", valueNotFilter: 0 },
    entreprises: { type: "array", keyValue: "uuid" },
    ofs: { type: "array", keyValue: "uuid" },
    certifications: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
      keyUrl: "certifications[]",
    },
    en_attente_de_ma_part: { type: "string" }, // a modifie -> add isWaitingMe en fonction toggle or select
    domaines: { type: "array", keyValue: "id", keyUrl: "domaines" },
    jury_associe: { type: "select", keyValue: "uuid", valueNotFilter: 0 },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);
  const response = await eCertifApi.get(
    `${candidatureURI}/chercher?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};
export const getCandidaturesJuryRequest = async (
  filters: any,
  options: any
) => {
  // a modifie -> add userId - mes candidatures

  const queryFiltersUrl = tools.generateFilterUrl(
    filters,
    candidatureJuryfilterType
  );
  const queryOptionsUrl = tools.generateOptionUrl(options);
  const response = await eCertifApi.get(
    `candidature/jurys/${filters.jury_associe}/candidatures?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};
export const exportCandidaturesJuryRequest = async (filters: any) => {
  const queryFiltersUrl = tools.generateFilterUrl(
    filters,
    candidatureJuryfilterType
  );
  const response = await eCertifApi.get(
    `candidature/jurys/${filters.jury_associe}/candidatures/export?${queryFiltersUrl}`
  );
  return response.data;
};

export const getCandidatureRequest = async (candidatureUuId: any) => {
  //const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.get(`${candidatureURI}/${candidatureUuId}`);
  return response.data;
};

export const postCandidatureRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(`${candidatureURI}`, data, xsrfHeader);
  return response.data;
};

export const putCandidatureRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${data.uuid}`,
    { ...data, _method: "PUT" },
    xsrfHeader
  );
  return response.data;
};
export const putCandidatureWithFormDataRequest = async (
  data: any,
  candidatureUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const putEnregistrerEvaluationCandidature = async (
  data: any,
  candidatureUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/sauvegarder-evaluation`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const putEnregistrerAvisFormateurCandidature = async (
  data: any,
  candidatureUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/sauvegarder-avis-formateur`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const putEnregistrerPositionnementCandidature = async (
  data: any,
  candidatureUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/sauvegarder-positionnement`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const patchGiveUpCandidatureRequest = async (
  candidatureUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${candidatureURI}/${candidatureUuid}/abandonner`,
    xsrfHeader
  );
  return response;
};

export const checkCandidatExistsRequest = async (candidatEmail: any) => {
  const response = await eCertifApi.get(
    `candidature/candidats/verifier?email=${candidatEmail}`
  );
  return response.data;
};

export const postCandidatureFormationRequest = async (
  candidatureUuid: any,
  formationData: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/parcours/formations`,
    formationData,
    xsrfHeader
  );
  return response.data;
};

export const putCandidatureFormationRequest = async (
  candidatureUuid: any,
  formationUuid: any,
  formationData: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/parcours/formations/${formationUuid}`,
    formationData,
    xsrfHeader
  );
  return response.data;
};

export const deleteFormationRequest = async (
  candidatureUuid: string,
  formationUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${candidatureURI}/${candidatureUuid}/parcours/formations/${formationUuid}`,
    xsrfHeader
  );
  return response;
};

export const postCandidatureExperienceRequest = async (
  candidatureUuid: any,
  experienceData: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/parcours/experiences`,
    experienceData,
    xsrfHeader
  );
  return response.data;
};

export const putCandidatureExperienceRequest = async (
  candidatureUuid: any,
  formationUuid: any,
  experienceData: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/parcours/experiences/${formationUuid}`,
    experienceData,
    xsrfHeader
  );
  return response.data;
};

export const deleteExperienceRequest = async (
  candidatureUuid: string,
  experienceUuid: string
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${candidatureURI}/${candidatureUuid}/parcours/experiences/${experienceUuid}`,
    xsrfHeader
  );
  return response;
};

export const patchAcceptCandidatureRequest = async (
  candidatureUuid: string
) => {
  const response = await eCertifApi.patch(
    `${candidatureURI}/${candidatureUuid}/inscription-jury/valider`
  );
  return response.data;
};

export const patchRejectCandidatureRequest = async (
  candidatureUuid: string
) => {
  const response = await eCertifApi.patch(
    `${candidatureURI}/${candidatureUuid}/inscription-jury/rejeter`
  );
  return response;
};

export const patchDesinscrireCandidatureRequest = async (
  candidatureUuid: string
) => {
  const response = await eCertifApi.patch(
    `${candidatureURI}/${candidatureUuid}/desinscrire-jury`
  );
  return response;
};

export const patchModifyCandidatureJuryRequest = async ({
  candidatureUuid,
  jury,
}: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.patch(
    `${candidatureURI}/${candidatureUuid}/inscription-jury/modifier`,
    jury,
    xsrfHeader
  );
  return response.data;
};
export const putModifyManyCandidaturesJuryRequest = async ({
  candidaturesUuid,
  juryUuid,
  isModificationEnMasse = false,
}: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.put(
    `candidature/jurys/${juryUuid}/modifier-candidatures`,
    {
      candidatures: candidaturesUuid,
      est_modification_en_masse: isModificationEnMasse,
    },
    xsrfHeader
  );
  return response.data;
};

export const getWaitingCandidatureRequest = async (
  filters: any,
  options: any
) => {
  const filterType = {
    libelle_jury: { type: "string" },
    date_debut_jury: { type: "string", valueNotFilter: null },
    date_fin_jury: { type: "string", valueNotFilter: null },
    entreprises: { type: "array", keyValue: "uuid" },
    ofs: { type: "array", keyValue: "uuid" },
    evaluateur: { type: "select", keyValue: "uuid" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);
  const queryOptionsUrl = tools.generateOptionUrl(options);

  const response = await eCertifApi.get(
    `${candidatureURI}/chercher/en-attente-validation-inscription-jury?${queryOptionsUrl}${queryFiltersUrl}`
  );
  return response.data;
};

export const postAvisMembreJury = async (
  avisJury: any,
  candidatureUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/avis-jury`,
    avisJury,
    xsrfHeader
  );
  return response.data;
};

export const postAvisPresidentJury = async (
  avisJury: any,
  candidatureUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/avis-president`,
    avisJury,
    xsrfHeader
  );
  return response.data;
};

export const postRegenerateDocuments = async (candidatureUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/documents/generate`,
    xsrfHeader
  );
  return response.data;
};

export const postInviteSecondEvaluateur = async (
  candidatureUuid: any,
  secondEvaluateur: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuid}/inviter-second-evaluateur`,
    secondEvaluateur,
    xsrfHeader
  );

  return response.data;
};

export const deleteInvitationSecondEvaluateur = async (
  candidatureUuid: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${candidatureURI}/${candidatureUuid}/annuler-second-evaluateur`,
    xsrfHeader
  );

  return response.data;
};

export const getExportCandidatures = async (filters: any) => {
  filters.en_attente_de_ma_part = undefined;
  const filterType = {
    nom: { type: "string" },
    prenom: { type: "string" },
    statut: { type: "select", keyValue: "key", valueNotFilter: 0 },
    statuts: { type: "array", keyValue: "key", valueNotFilter: 0 },
    resultat: { type: "string", keyUrl: "resultat[]" },
    //domaine: { type: "select", keyValue: "key", valueNotFilter: 0 },
    entreprises: { type: "array", keyValue: "uuid" },
    ofs: { type: "array", keyValue: "uuid" },
    certifications: {
      type: "select",
      keyValue: "id",
      valueNotFilter: undefined,
      keyUrl: "certifications[]",
    },
    //en_attente_de_ma_part: { type: "string" }, // a modifie -> add isWaitingMe en fonction toggle or select
    domaines: { type: "array", keyValue: "id", keyUrl: "domaines" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  try {
    const response = await eCertifApi.get(
      `candidature/exports/candidatures?paginate=false${queryFiltersUrl}`,
      {
        responseType: "blob",
      }
    );

    return true;
  } catch (err) {
    throw err;
  }
};
export const deleteDecisionPresident = async (candidatureUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `${candidatureURI}/${candidatureUuid}/annuler-decision-jury`,
    xsrfHeader
  );

  return response.data;
};

export const getCandidatureDocumentsRequest = async (candidatureUuId: any) => {
  //const xsrfHeader = await getCSRFCookieRequest();
  const response = await eCertifApi.get(
    `${candidatureURI}/${candidatureUuId}/documents/etat-generation`
  );
  return response.data;
};

export const postMECRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `candidature/mise-en-conformite/effectuer`,
    data,
    xsrfHeader
  );
  return response.data;
};

export const getMECRecipients = async (filters: any) => {
  const filterType = {
    candidature_uuid: { type: "string" },
    candidature_statut: { type: "string" },
  };

  const queryFiltersUrl = tools.generateFilterUrl(filters, filterType);

  const response = await eCertifApi.get(
    `candidature/mise-en-conformite/utilisateurs?${queryFiltersUrl}`
  );
  return response.data;
};

export const postAskMECRequest = async (data: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `candidature/mise-en-conformite/demander`,
    data,
    xsrfHeader
  );
  return response.data;
};
export const cancelAskMECRequest = async (mecUuid: any) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.delete(
    `candidature/mise-en-conformite/${mecUuid}/annuler`,
    xsrfHeader
  );
  return response.data;
};

export const patchEnvoyerDocumentRequest = async (
  data: any,
  candidatureUuId: any
) => {
  const xsrfHeader = await getCSRFCookieRequest();

  const response = await eCertifApi.post(
    `${candidatureURI}/${candidatureUuId}/envoyer-document`,
    data,
    xsrfHeader
  );

  return response;
};
