import {
  ActionButton,
  Dropdown,
  IIconProps,
  Label,
  List,
  Stack,
  Text,
} from "@fluentui/react";
import { useContext } from "react";
import useConstants from "../../../hooks/useConstants";
import { commonInputStyles } from "../../../styles";
import tools from "../../../utils/tools";
import { CertificationContext } from "../CertificationFormContext/CertificationFormContext";
import { competenceBlockItemStyles } from "./CompetenceBlockItem.styles";
import useCustomModal from "../../../Common/Overlays/CustomModal/useCustomModal";
import CompetenceBlockInformationsModal from "./CompetenceBlockInformationsModal/CompetenceBlockInformationsModal";

const removeIcon: IIconProps = { iconName: "Cancel" };

const CompetenceBlockItem = ({ block, forOption }: any) => {
  const { actions, blocksDisabled }: any = useContext(CertificationContext);
  const { constants } = useConstants();
  const { onChangeBlock, onRemoveBlock } = actions;
  const { actions: modalActions } = useCustomModal();
  const onRenderCell = (certif: any) => {
    return (
      <div>
        {certif.libelle}
        <span className="text-muted"> - {certif.branche_creatrice?.nom}</span>
      </div>
    );
  };

  const onChangeBlockStatus = (e: any, option: any) => {
    onChangeBlock({ ...block, statut: option.id }, forOption);
  };

  const statuses = constants["CERTIFICATIONS"][
    "BLOC_COMPETENCE_OPTION_STATUTS"
  ].map((s: any) => ({ ...s, text: s.text, key: s.id }));

  const openShowCompetenceModal = (block: any) => {
    modalActions.openModal({
      header: `Compétences du bloc: ${block.libelle}`,
      body: (
        <CompetenceBlockInformationsModal competences={block.competences} />
      ),
    });
  };

  return (
    <Stack
      styles={competenceBlockItemStyles}
      horizontal
      horizontalAlign="space-between"
      onClick={(e: any) => openShowCompetenceModal(block)}
    >
      <Stack.Item>
        <div style={{ marginBottom: 15 }}>
          <Text variant="large">
            {block.reference && block.reference + " - "}
            {block.libelle}
          </Text>
          <Text>
            <span className="text-muted">- {block.branche_creatrice?.nom}</span>
          </Text>
        </div>
        <List items={block.certifications} onRenderCell={onRenderCell} />
      </Stack.Item>
      <Stack.Item>
        {!blocksDisabled && (
          <div
            className="d-center"
            style={{ justifyContent: "end", marginBottom: 10 }}
          >
            <ActionButton
              iconProps={removeIcon}
              text="Retirer ce bloc"
              onClick={() => onRemoveBlock(block, forOption)}
              style={{ padding: 0 }}
            />
          </div>
        )}
        <div className="d-center" style={{ justifyContent: "start" }}>
          <Label style={{ display: "inline", marginRight: 5 }}>
            Statut du bloc :{" "}
          </Label>
          <Dropdown
            placeholder="Sélectionnez un statut"
            selectedKey={
              (tools.findIn(statuses, block.statut, "id") as any).key || null
            }
            options={statuses}
            //calloutProps={{ doNotLayer: true }}
            onChange={onChangeBlockStatus}
            styles={commonInputStyles.dropdownStyle}
            required
            disabled={blocksDisabled}
          />
        </div>
      </Stack.Item>
    </Stack>
  );
};

export default CompetenceBlockItem;
